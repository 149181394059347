/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PseudoBlogPageContent {
  margin: 3rem;
}
.PseudoBlogPageContent__byline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 4rem;
}
@media (max-width: 767px) {
  .PseudoBlogPageContent__byline {
    margin: 0;
  }
}
.PseudoBlogPageContent__author {
  align-self: flex-start;
}
.PseudoBlogPageContent__author__portraitFrame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.PseudoBlogPageContent__author__portraitFrame__image {
  border-radius: 100%;
  margin: 0 auto;
  width: 9rem;
  height: 9rem;
  border: 1.2rem solid #f9f6ef;
  background-position: center center;
  background-size: cover;
}
.PseudoBlogPageContent__author__portraitFrame__name {
  margin-left: 2rem;
  align-self: center;
}
.PseudoBlogPageContent__date {
  align-self: center;
}
.PseudoBlogPageContent__topImageFrame {
  width: 100%;
  margin-top: 4rem;
}
@media (max-width: 767px) {
  .PseudoBlogPageContent__topImageFrame {
    margin-top: 2rem;
  }
}
.PseudoBlogPageContent__topImageFrame__image {
  width: 100%;
}
.PseudoBlogPageContent__buttonFrame {
  margin: 6rem auto;
  width: 21rem;
}
@media (max-width: 767px) {
  .PseudoBlogPageContent__buttonFrame {
    width: 18rem;
  }
}
.PseudoBlogPageContent__body {
  margin: 6rem 10rem;
  font-size: 1.8rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .PseudoBlogPageContent__body {
    margin: 2rem;
    font-size: 1.5rem;
  }
}
